<template>
   <main>
      <!-- HEAD -->
      <section class="slider__area pt-30 grey-bg">
         <div class="container">
            <div class="row">
               <div class="col-xl-2 custom-col-2 d-none d-xl-block">
                  <div class="cat__menu-wrapper">
                     <div class="cat-toggle">
                        <button type="button" class="cat-toggle-btn"><i class="fas fa-bars"></i> Categorias</button>
                        <div class="cat__menu">
                           <nav id="mobile-menu">
                              <ul class="overflow">
                                 <li v-for="(categoria, index) in listaCategorias" :key="index">
                                    <a href="javascript:;" class="hover" :title="'Buscar por '+ categoria.categoria" @click="pesquisar(categoria.categoria)">
                                       {{ categoria.categoria }}
                                    </a>
                                 </li>
                              </ul>
                           </nav>
                        </div>
                     </div>
                  </div>
               </div>

               <div class="col-xl-10 custom-col-10 col-lg-12">
                  <div class="slider__inner slider-active">
                     <div class="single-slider w-img">
                        <img src="@/assets/cliente/img/slider/slider-02.jpg" alt="slider">
                     </div>
                     <div class="single-slider w-img">
                        <img src="@/assets/cliente/img/slider/slider-03.jpg" alt="slider">
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>

      <!-- BANNERS -->
      <section class="features__area grey-bg-2 pt-30 pb-20 pl-10 pr-10">
         <div class="container">
            <div class="row row-cols-xxl-4 row-cols-xl-4 row-cols-lg-2 row-cols-md-2 row-cols-sm-2 row-cols-1 gx-0">
               <div class="col">
                  <div class="features__item d-flex white-bg">
                     <div class="features__icon mr-15">
                        <i class="fal fa-truck"></i>
                     </div>
                     <div class="features__content">
                        <h6>Entrega</h6>
                        <p>Sistema de entrega rápida e garantida</p>
                     </div>
                  </div>
               </div>
               <div class="col">
                  <div class="features__item d-flex white-bg">
                     <div class="features__icon mr-15">
                        <i class="fal fa-lock"></i>
                     </div>
                     <div class="features__content">
                        <h6>Preços baixos</h6>
                        <p>Nossas promoções também no ambiente virtual</p>
                     </div>
                  </div>
               </div>
               <div class="col">
                  <div class="features__item d-flex white-bg">
                     <div class="features__icon mr-15">
                        <i class="fal fa-thumbs-up"></i>
                     </div>
                     <div class="features__content">
                        <h6>Confiança</h6>
                        <p>Compre produtos de qualidade direto do seu lar</p>
                     </div>
                  </div>
               </div>
               <div class="col">
                  <div class="features__item features__item-last d-flex white-bg">
                     <div class="features__icon mr-15">
                        <i class="fal fa-badge-dollar"></i>
                     </div>
                     <div class="features__content">
                        <h6>Descontos</h6>
                        <p>Consiga descontos em suas compras</p>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>

      <!-- BANNERS -->
      <section class="banner__area pt-20 grey-bg-2 d-none d-lg-block">
         <div class="container">
            <div class="row">
               <div class="col-xl-6 col-lg-6">
                  <div class="banner__item mb-30 w-img">
                     <a href="javascript:;"><img src="@/assets/cliente/img/banner/banner-4.jpg" alt=""></a>
                  </div>
               </div>
               <div class="col-xl-6 col-lg-6">
                  <div class="banner__item mb-30 w-img">
                     <a href="javascript:;"><img src="@/assets/cliente/img/banner/banner-5.jpg" alt=""></a>
                  </div>
               </div>
            </div>
         </div>
      </section>

      <section class="deal__area grey-bg">
         <div class="container">
            <div class="row">
               <div class="col-xl-6 pb-45 pt-25">
                  <div class="section__head d-md-flex justify-content-between mb-20">
                     <div class="section__title">
                        <h3>Mais vendidos <span class="ms-1">Hortifruti</span></h3>
                     </div>
                  </div>
                  <div class="product__electronic-3 t-nav owl-carousel" v-if="listaProdutos.segunda.length >= 3">
                     <oferta class="product__electronic-item" v-for="(produto, index) in listaProdutos.segunda" :key="('segunda'+ index)" :produto="produto" :page="'OFERTAS'" />
                  </div>
                  <div class="t-nav" v-else>Sem produtos</div>
               </div>

               <div class="col-xl-6 pb-45 pt-25">
                  <div class="section__head d-md-flex justify-content-between mb-20">
                     <div class="section__title">
                        <h3>Mais vendidos <span class="ms-1">Panificadora</span></h3>
                     </div>
                  </div>
                  <div class="product__electronic-3 t-nav owl-carousel" v-if="listaProdutos.terca.length >= 3">
                     <oferta class="product__electronic-item" v-for="(produto, index) in listaProdutos.terca" :key="('terca'+ index)" :produto="produto" :page="'OFERTAS'" />
                  </div>
                  <div class="t-nav" v-else>Sem produtos</div>
               </div>

               <div class="col-xl-6 pb-45 pt-25">
                  <div class="section__head d-md-flex justify-content-between mb-20">
                     <div class="section__title">
                        <h3>Mais vendidos <span class="ms-1">Bebidas</span></h3>
                     </div>
                  </div>
                  <div class="product__electronic-3 t-nav owl-carousel" v-if="listaProdutos.quarta.length >= 3">
                     <oferta class="product__electronic-item" v-for="(produto, index) in listaProdutos.quarta" :key="('quarta'+ index)" :produto="produto" :page="'OFERTAS'" />
                  </div>
                  <div class="t-nav" v-else>Sem produtos</div>
               </div>

               <div class="col-xl-6 pb-45 pt-25">
                  <div class="section__head d-md-flex justify-content-between mb-20">
                     <div class="section__title">
                        <h3>Mais vendidos <span class="ms-1">Açougue</span></h3>
                     </div>
                  </div>
                  <div class="product__electronic-3 t-nav owl-carousel" v-if="listaProdutos.quinta.length >= 3">
                     <oferta class="product__electronic-item" v-for="(produto, index) in listaProdutos.quinta" :key="('quinta'+ index)" :produto="produto" :page="'OFERTAS'" />
                  </div>
                  <div class="t-nav" v-else>Sem produtos</div>
               </div>

               <div class="col-xl-6 pb-45 pt-25">
                  <div class="section__head d-md-flex justify-content-between mb-20">
                     <div class="section__title">
                        <h3>Mais vendidos <span class="ms-1">Limpeza</span></h3>
                     </div>
                  </div>
                  <div class="product__electronic-3 t-nav owl-carousel" v-if="listaProdutos.fimSemana.length >= 3">
                     <oferta class="product__electronic-item" v-for="(produto, index) in listaProdutos.fimSemana" :key="('fimSemana'+ index)" :produto="produto" :page="'OFERTAS'" />
                  </div>
                  <div class="t-nav" v-else>Sem produtos</div>
               </div>
            </div>
         </div>
      </section>
   </main>
</template>

<script>

import Vue from 'vue'
import $ from 'jquery'
import { mapState } from 'vuex'
import oferta from '@/components/home/Produto.vue'

export default {
	name: 'Home',
   computed: {
		... mapState({
			listaCategorias: state => state.listaCategorias,
			listaProdutos: state => state.listaProdutos,
			isRouteAdmin: state => state.isRouteAdmin
		})
	},
   components: {
		oferta
	},
   methods: {
      pesquisar : function (categoria) {
         this.$store.dispatch('pesquisar', {'valor': '', 'categoria': categoria, 'subcategoria': 'Todas subcategorias', 'marca': null})
      },
      doAnimations : function (elements) {
         var animationEndEvents = 'webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend';
            
         elements.each(function () {
            var $this = $(this);
            var $animationDelay = $this.data('delay');
            var $animationType = 'animated ' + $this.data('animation');
            $this.css({
               'animation-delay': $animationDelay,
               '-webkit-animation-delay': $animationDelay
            });
            $this.addClass($animationType).one(animationEndEvents, function () {
               $this.removeClass($animationType);
            });
         });
      }
   },
   mounted() {
      let ref = this;

      if (!this.isRouteAdmin) {
         if (localStorage.theme != null) {
            $("html").removeClass(localStorage.theme)
         }

         Vue.nextTick(function() {
            $('.cat-toggle-btn').on('click', function () {
               $('.cat__menu').slideToggle(500);
            });
   
            $('.slider-active').on('init', function () {
               var $firstAnimatingElements = $('.single-slider:first-child').find('[data-animation]');
               ref.doAnimations($firstAnimatingElements)
            });
   
            $('.slider-active').on('beforeChange', function (e, slick, currentSlide, nextSlide) {
               var $animatingElements = $('.single-slider[data-slick-index="' + nextSlide + '"]').find('[data-animation]');
               ref.doAnimations($animatingElements)
            });
   
            $('.slider-active').slick({
               autoplay: true,
               autoplaySpeed: 8000,
               dots: true,
               fade: false,
               arrows: false,
               prevArrow: '<button type="button" class="slick-prev"><i class="fal fa-angle-left"></i></button>',
               nextArrow: '<button type="button" class="slick-next"><i class="fal fa-angle-right"></i></button>',
               responsive: [{
                  breakpoint: 767,
                  settings: {
                     dots: false,
                     arrows: false
                  }
               }]
            });
   
            if ($('.product__electronic-3').length != 0) {
               $('.product__electronic-3').owlCarousel({
                  loop:true,
                  margin:20,
                  autoplay:false,
                  autoplayTimeout:60000,
                  smartSpeed:500,
                  nav:false,
                  dots:false,
                  responsive:{
                     0:{
                        items:1
                     },
                     576:{
                        items:2
                     },
                     767:{
                        items:3
                     },
                     992:{
                        items:3
                     },
                     1200:{//x2 para full
                        items:2
                     },
                     1600:{//x2 para full
                        items:3
                     }
                  }
               });
            }
         }.bind(this));
      }
   }
}

</script>